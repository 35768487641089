<template>
	<v-toolbar-items v-if="totalPages > 1">
		<slot/>
		<DocumentPagesNavigation
				v-bind="tooltip"
				:display-thumbnails="displayThumbnails"
				:page="page"
				:total-pages="totalPages"
		/>
		<ShowPDFDocumentPageThumbnails
				v-bind="tooltip"
				:display-thumbnails="displayThumbnails"
		/>
		<DocumentPageActions
				v-if="model && version"
				:tooltip="tooltip"
				:document="model"
				:is-editable="isEditable"
				:is-readonly="isReadonly"
				:page="page"
				:version="version"/>
	</v-toolbar-items>
</template>

<script>
export default {
	name: 'DocumentPages',
	components: {
		DocumentPagesNavigation: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentPages/DocumentPagesNavigation')
		}),
		DocumentPageActions: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentPages/DocumentPageActions')
		}),
		ShowPDFDocumentPageThumbnails: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentPages/Actions/ShowPDFDocumentPageThumbnails')
		})
	},
	props: {
		displayThumbnails: {
			type: Boolean,
			required: true
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: true
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: false
		},
		page: {
			type: Number,
			required: true,
			validator: value => {
				return value >= 0
			}
		},
		totalPages: {
			type: Number,
			required: true,
			validator: value => {
				return value >= 0
			}
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Object,
			required: false,
			default: null
		},
		tooltip: {
			type: Object,
			required: false,
			default: () => ({
				bottom: true,
			})
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		document: function () {
			return this.model
		}
	}
}
</script>
